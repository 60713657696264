/* ========================== Empty Order Page =================== */
.empty_order {

    header {
        padding: 1rem 0;
    }

    header .rating {
        font-size: 1rem;
        margin-right: 1rem;
    }

    .order_logo_set img {
        width: 50px;
    }

    .empty-cart {
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        padding: 2rem;
        text-align: center;
        background-color: white;
        max-width: 850px;
        margin: 5rem auto;
    }

    .empty-cart .icon {
        font-size: 4rem;
        color: #6c757d;
    }

    .empty-cart h2 {
        font-size: 1.5rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    .empty-cart p {
        color: #6c757d;
        margin-bottom: 1.5rem;
    }

    .empty-cart .btn {
        background-color: #ff9800;
        border: none;
        color: white;
        padding: 0.5rem 2rem;
        font-size: 1rem;
        border-radius: 5px;
    }
}


/* ====================== Card_section_after_order ========================== */

.Card_section_after_order {

    header {
        padding: 1rem 0;
    }

    header .rating {
        font-size: 1rem;
        margin-right: 1rem;
    }

    .order_logo_set img {
        width: 50px;
    }


    .card {
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        margin-bottom: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .card-header {
        background-color: #f1f1f1;
        font-weight: bold;
    }

    .card-body {
        padding: 20px;
    }

    .order-details h5 {
        margin-bottom: 15px;
    }

    .btn-orange {
        background-color: #ff9800;
        color: #fff;
        border: none;
    }

    .btn-orange:hover {
        background-color: #e08900;
    }

    .order-total {
        background-color: #ffffff;
        padding: 20px;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
    }

    .order-total h5 {
        font-weight: bold;
    }

    .order-total .total-amount {
        font-size: 1.5rem;
        color: #000;
    }

    .btn-continue {
        background-color: #ff9800;
        color: #fff;
        border: none;
        width: 100%;
        padding: 10px;
        font-size: 1rem;
    }

    .btn-continue:hover {
        background-color: #e08900;
    }

    .payment-mode img {
        margin-right: 10px;
    }
}


.cart-count {
    background-color: red;
    color: white;
    border-radius: 50%;
    /* padding: 2px 6px; */
    font-size: 12px;
    position: absolute;
    top: 7px;
    right: 55px;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  