.signin {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 0 10px;
  background: linear-gradient(#1e2330, #1d253a, #090e21);
}

.signin .signin_logo{
  position: absolute;
  top: 5%;
  left: 5%;
  img{
    cursor: pointer;
  }
}
.wrapper {
  background: white;
  width: 400px;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  border: 1px solid rgba(136, 132, 132, 0.5);
  filter: drop-shadow(2px 2px 5px rgba(220, 218, 218, 0.4));
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.signin form {
  display: flex;
  flex-direction: column;
}

.signin h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: black;
}

.signin .input-field {
  position: relative;
  border-bottom: 2px solid black;
  margin: 15px 0;
}

.signin .input-field label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: black;
  font-size: 16px;
  pointer-events: none;
  transition: 0.15s ease;
}

.signin .input-field input {
  width: 100%;
  height: 30px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  color: black;
}

.signin .input-field input:focus~label,
.signin .input-field input:valid~label {
  font-size: 0.8rem;
  top: 10px;
  transform: translateY(-120%);
}

.signin .forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 25px 0 35px 0; */
  color: black;
}

.signin #remember {
  accent-color: black;
}

.signin .forget label {
  display: flex;
  align-items: center;
}

.signin .forget label p {
  margin-left: 8px;
  margin-bottom: 0;
}
.signin .forget a{
  font-size: 13px;
}

.signin .wrapper a {
  color: black;
  text-decoration: none;
}

.signin .wrapper a:hover {
  text-decoration: underline;
}

.signin  button {
  background: rgb(29, 29, 186);
  color: white;
  font-weight: 600;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 16px;
  border: 2px solid transparent;
  transition: all 0.3s ease-in;
}

.signin button:hover {
  color: white;
  background: rgb(0, 0, 0);
  transform: scale(0.95);
  filter: blur(.7px);
}

.signin .register {
  text-align: end;
  margin-top: 10px;
  color: black;
  transition: filter 0.3s ease;
}
