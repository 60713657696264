.change-password-container {
    max-width: 400px;
    margin: 50px auto;
    text-align: center;
  }
  
  .change-password-container h2 {
    margin-bottom: 20px;
  }
  
  .change-password-container  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .change-password-container  .password-input {
    display: flex;
    align-items: center;
  }
  
  .change-password-container  .password-input input {
    flex: 1;
    padding-right: 40px;
  }
  
  .change-password-container  .toggle-password {
    cursor: pointer;
    margin-left: -30px;
  }
  
  .change-password-container  .button-group {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
  }
  
  .change-password-container  button {
    width: 100px;
    height: 40px;
  }
  