

/* check boxes */
.text {
    margin-left: 30px;
}

/* Style inputs with type="text", select elements and textareas */
.honor_code input[type=e-mail],
.honor_code select,
.honor_code textarea {
    width: 100%;
    /* Full width */
    padding: 12px;
    /* Some padding */
    border: 1px solid #ccc;
    /* Gray border */
    border-radius: 4px;
    /* Rounded borders */
    box-sizing: border-box;
    /* Make sure that padding and width stays in place */
    margin-top: 6px;
    /* Add a top margin */
    margin-bottom: 16px;
    /* Bottom margin */
    resize: vertical
        /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
.honor_code input[type=submit] {
    background-color: #4169E1;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
.honor_code input[type=submit]:hover {
    background-color: #afb4c4;
}

/* Add a background color and some padding around the form */
.container-comment {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
}