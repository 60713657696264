.color-change_gray {
    background-color: #F2F2F2;
}

.color-change_white {
    background-color: white;
}

.pt-9 {
    padding-top: 9rem;
}

.pt-3 {
    padding-top: 3rem;
}

.set_button_position {
    position: relative;
    bottom: 19rem;
    right: 9rem;
    width: 45%;
    color: black;


    a {

        font-weight: bold;
        font-size: 17px;
        border-radius: 20px;
        background-color: white !important;

        &:hover {
            color: white;
            background-color: white !important;
        }
    }
}

.set_button_position button {
    background-color: #0D6EFD;
    color: white;

    :hover {
        background-color: #F2F2F2;
    }
}

.order_now_button button {
    background-color: #0D6EFD;
    color: white;
    border: 1px solid #0D6EFD;

    :hover {
        background-color: white !important;
        color: black !important;
        border: 1px solid black !important;
    }
}

@media (min-width:320px) and (max-width:425px) {
    .set_button_position {
        position: relative;
        bottom: 8rem;
        right: 4rem;
        width: 45%;
        color: black;

        button {
            font-size: 12px;
        }
    }

}

@media (max-width:768px) {
    .set_button_position {
        position: relative;
        bottom: 12rem;
        right: 5rem;
        width: 45%;
        color: black;

        button {
            font-size: 12px;
        }
    }
}