.profile-container {
  text-align: center;
  padding: 20px;
}

.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-image {
  position: relative;
}

.profile-image img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.edit-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #fff;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.account-details {
  margin-top: 20px;
  text-align: left;
  display: inline-block;
  width: 400px;
  border: 2px solid rgba(0, 0, 0, 0.11);
  padding: 10px;
}

.account-details h3 {
  margin-bottom: 15px;
  
}

.account-details p {
  margin: 5px 0;
}

.form-group {
  margin-bottom: 15px;
}
