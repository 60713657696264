

.main-banner {
    background-color: rgba(43, 42, 42, 0.308);
    background-image: url("../../../public/images/Trailor writing.jpg");
    height:85vh ;
    width: auto;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1.9;

}

 .button-container{
    /* margin-top: 10rem; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-banner .button-container  button{
    background-color: #1b18ab;
    border-radius: 25px;
    border: 3px solid white;
    color: white;
    width: 200px;
}
.main-banner .button-container  button:hover{
    background-color: white;
    border: 3px solid #040273;
    color: black !important;
    width: 200px;
}
